import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {

  /**
   * 删除
   * @returns 
   */
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.ANNOUNCEMENT_DELETE}/${id}`
    });
    return rs.data;
  },
  /**
   * 详情
   * @param {*} id 
   * @returns 
   */
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.ANNOUNCEMENT_DETAIL}/${id}`
    });
    return rs.data;
  },
  /**
   * 创建
   * @param {*} data 
   * @returns 
   */
  async create(data) {
    const rs = await req.postJson({
      url: URLS.ANNOUNCEMENT_CREATE,
      data
    });
    return rs.data;
  },
  /**
     * 列表
     * @param {*} data 
     * @returns 
     */
  async list(data) {
    const rs = await req.postJson({
      url: URLS.ANNOUNCEMENT_LIST,
      data
    });
    return rs.data;
  },
}