
import indexApi from "~/utils/api/index";

export default {
  name: "page-index",
  async fetch() {
    await this.combined();
  },
  data() {
    return {
      threads: [],
      questions: [],
      heroGalleries: [],
      loading: true,
    };
  },
  // activated() {
  //   this.$store.dispatch("navbar/updateNavbar", {
  //     backgroundColor: "#030d1e",
  //     mode: "dark",
  //     borderBottom: "none"
  //   });
  // },
  // deactivated() {
  //   this.$store.dispatch("navbar/updateNavbar", null);
  // },
  methods: {
    /**
     * 取主题
     */
    async combined() {
      try {
        this.loading = true;

        const rs = await indexApi.combined();
        if (rs) {
          const data = rs.data;
          this.threads = data.threads || [];
          this.questions = data.questions || [];
          this.heroGalleries = data.heroGalleries || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
};
