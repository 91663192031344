
import format from "~/utils/format";
import metasMixins from "~/mixins/metas";
import announcementApi from "~/utils/api/announcement";

export default {
  mixins: [metasMixins],
  head() {
    return {
      title: this.$app.getTitle('系统公告'),
    };
  },
  data() {
    return {
      announcements: [],
      loading: false,
    }
  },
  async fetch() {
    await this.getAnnouncement();
  },
  methods: {
    /**
     * 格式化时间
     * @param {*} d 
     */
    formatDate(d) {
      return format.formatDate(d, 'YYYY-MM-DD HH:mm');
    },
    /**
     * 取主题列表
     * @param {*} targetMetas 
     */
    async getAnnouncement(targetMetas) {
      const { metas } = this;

      const willLoadMetas = targetMetas || metas;
      let data = {
        ...willLoadMetas,
      };

      try {
        this.loading = true;
        const rs = await announcementApi.list({ ...data });
        if (rs) {
          this.metas = rs.metas;
          this.announcements = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
  },
};
