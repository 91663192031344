import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

import _06bd2de4 from '../pages/about/index.vue'
import _32c32ad7 from '../pages/faq/index.vue'
import _c49330ea from '../pages/studio/index.vue'
import _030f0da8 from '../pages/template.vue'
import _460a05dc from '../pages/tools/index.vue'
import _51cd14ce from '../pages/upcoming.vue'
import _041b0d69 from '../pages/about/medium.vue'
import _5eca0269 from '../pages/announcements/detail/index.vue'
import _0d3b883e from '../pages/faq/ask.vue'
import _9c976e62 from '../pages/posts/empty.vue'
import _1fd41819 from '../pages/product/domain/index.vue'
import _2571bdae from '../pages/product/wbs/index.vue'
import _0b40b00b from '../pages/system/links.vue'
import _6adc62d2 from '../pages/system/report.vue'
import _4a30aa94 from '../pages/threads/content/index.vue'
import _2d1828b6 from '../pages/threads/index_.vue'
import _221eeb8d from '../pages/tools/btoa/index.vue'
import _249ba026 from '../pages/tools/punycode/index.vue'
import _c459417a from '../pages/tools/webmaster/index.vue'
import _9f5a63d6 from '../pages/users/create.vue'
import _63e04e40 from '../pages/users/login.vue'
import _a5aab874 from '../pages/users/reset.vue'
import _917f4a02 from '../pages/users/settings/index.vue'
import _5d7ce5e0 from '../pages/product/domain/price.vue'
import _f324771e from '../pages/product/domain/search.vue'
import _efd41194 from '../pages/product/domain/tld/index.vue'
import _38bbfd9e from '../pages/product/trade/detail/index.vue'
import _7ee4f5a4 from '../pages/users/settings/address/index.vue'
import _2fe36403 from '../pages/users/settings/hao.vue'
import _6352dccd from '../pages/users/settings/profiles.vue'
import _2f004c73 from '../pages/users/settings/address/add.vue'
import _78604190 from '../pages/index.vue'
import _4cff262b from '../pages/product/domain/tld/_id.html.vue'
import _8b06d360 from '../pages/product/trade/detail/_id.html.vue'
import _785e80ac from '../pages/threads/tag/list/_page.vue'
import _e23b2bd0 from '../pages/announcements/detail/_id.html.vue'
import _f9533af0 from '../pages/faq/list/_page.vue'
import _5a151fa1 from '../pages/product/trade/_page.vue'
import _6e2e4e0d from '../pages/threads/content/_id.html.vue'
import _23b0a402 from '../pages/tools/spider/_ip.vue'
import _2d0b48d6 from '../pages/users/detail/_id.vue'
import _45bbf48c from '../pages/threads/tag/_slug/_page.vue'
import _49338d69 from '../pages/announcements/_page.vue'
import _1eaa42ea from '../pages/faq/_id.html.vue'
import _f297971a from '../pages/threads/_categorySlug/detail/index.vue'
import _7d7783ce from '../pages/threads/_categorySlug/detail/_id.html.vue'
import _3f63b0b6 from '../pages/posts/_threadID/_page.vue'
import _a4d59ac2 from '../pages/threads/_categorySlug/_page.vue'
import _6da11276 from '../pages/search/_indexType/_keyword/_page.vue'

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _06bd2de4,
    name: "about"
  }, {
    path: "/faq",
    component: _32c32ad7,
    name: "faq"
  }, {
    path: "/studio",
    component: _c49330ea,
    name: "studio"
  }, {
    path: "/template",
    component: _030f0da8,
    name: "template"
  }, {
    path: "/tools",
    component: _460a05dc,
    name: "tools"
  }, {
    path: "/upcoming",
    component: _51cd14ce,
    name: "upcoming"
  }, {
    path: "/about/medium",
    component: _041b0d69,
    name: "about-medium"
  }, {
    path: "/announcements/detail",
    component: _5eca0269,
    name: "announcements-detail"
  }, {
    path: "/faq/ask",
    component: _0d3b883e,
    name: "faq-ask"
  }, {
    path: "/posts/empty",
    component: _9c976e62,
    name: "posts-empty"
  }, {
    path: "/product/domain",
    component: _1fd41819,
    name: "product-domain"
  }, {
    path: "/product/wbs",
    component: _2571bdae,
    name: "product-wbs"
  }, {
    path: "/system/links",
    component: _0b40b00b,
    name: "system-links"
  }, {
    path: "/system/report",
    component: _6adc62d2,
    name: "system-report"
  }, {
    path: "/threads/content",
    component: _4a30aa94,
    name: "threads-content"
  }, {
    path: "/threads/index_",
    component: _2d1828b6,
    name: "threads-index_"
  }, {
    path: "/tools/btoa",
    component: _221eeb8d,
    name: "tools-btoa"
  }, {
    path: "/tools/punycode",
    component: _249ba026,
    name: "tools-punycode"
  }, {
    path: "/tools/webmaster",
    component: _c459417a,
    name: "tools-webmaster"
  }, {
    path: "/users/create",
    component: _9f5a63d6,
    name: "users-create"
  }, {
    path: "/users/login",
    component: _63e04e40,
    name: "users-login"
  }, {
    path: "/users/reset",
    component: _a5aab874,
    name: "users-reset"
  }, {
    path: "/users/settings",
    component: _917f4a02,
    name: "users-settings"
  }, {
    path: "/product/domain/price",
    component: _5d7ce5e0,
    name: "product-domain-price"
  }, {
    path: "/product/domain/search",
    component: _f324771e,
    name: "product-domain-search"
  }, {
    path: "/product/domain/tld",
    component: _efd41194,
    name: "product-domain-tld"
  }, {
    path: "/product/trade/detail",
    component: _38bbfd9e,
    name: "product-trade-detail"
  }, {
    path: "/users/settings/address",
    component: _7ee4f5a4,
    name: "users-settings-address"
  }, {
    path: "/users/settings/hao",
    component: _2fe36403,
    name: "users-settings-hao"
  }, {
    path: "/users/settings/profiles",
    component: _6352dccd,
    name: "users-settings-profiles"
  }, {
    path: "/users/settings/address/add",
    component: _2f004c73,
    name: "users-settings-address-add"
  }, {
    path: "/",
    component: _78604190,
    name: "index"
  }, {
    path: "/product/domain/tld/:id.html",
    component: _4cff262b,
    name: "product-domain-tld-id.html"
  }, {
    path: "/product/trade/detail/:id.html",
    component: _8b06d360,
    name: "product-trade-detail-id.html"
  }, {
    path: "/threads/tag/list/:page?",
    component: _785e80ac,
    name: "threads-tag-list-page"
  }, {
    path: "/announcements/detail/:id.html",
    component: _e23b2bd0,
    name: "announcements-detail-id.html"
  }, {
    path: "/faq/list/:page?",
    component: _f9533af0,
    name: "faq-list-page"
  }, {
    path: "/product/trade/:page?",
    component: _5a151fa1,
    name: "product-trade-page"
  }, {
    path: "/threads/content/:id.html",
    component: _6e2e4e0d,
    name: "threads-content-id.html"
  }, {
    path: "/tools/spider/:ip?",
    component: _23b0a402,
    name: "tools-spider-ip"
  }, {
    path: "/users/detail/:id?",
    component: _2d0b48d6,
    name: "users-detail-id"
  }, {
    path: "/threads/tag/:slug?/:page?",
    component: _45bbf48c,
    name: "threads-tag-slug-page"
  }, {
    path: "/announcements/:page?",
    component: _49338d69,
    name: "announcements-page"
  }, {
    path: "/faq/:id.html",
    component: _1eaa42ea,
    name: "faq-id.html"
  }, {
    path: "/threads/:categorySlug?/detail",
    component: _f297971a,
    name: "threads-categorySlug-detail"
  }, {
    path: "/threads/:categorySlug?/detail/:id.html",
    component: _7d7783ce,
    name: "threads-categorySlug-detail-id.html"
  }, {
    path: "/posts/:threadID?/:page?",
    component: _3f63b0b6,
    name: "posts-threadID-page"
  }, {
    path: "/threads/:categorySlug?/:page?",
    component: _a4d59ac2,
    name: "threads-categorySlug-page"
  }, {
    path: "/search/:indexType?/:keyword?/:page?",
    component: _6da11276,
    name: "search-indexType-keyword-page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
