
import format from "~/utils/format";
import announcementApi from "~/utils/api/announcement";

export default {
  name: "page-announcement-detail",
  head() {
    const {announcement} = this;

    return {
      title: this.$app.getTitle(announcement.name || "无标题"),
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  async asyncData({ params, error }) {
    let announcement = {};
    const id = params.id;
    try {

      // 查问答
      const rs = await announcementApi.detail(id);
      if (rs) {
        announcement = rs.data;
      }

    } catch (err) {
      error({ status: 404, message: err.message || "未知错误" });
    }

    return {
      announcement,
      // 覆盖loading
      loading: false
    }
  },
  methods: {
    formatDate(t) {
      return format.formatDate(t, "YYYY-MM-DD");
    },
  },
};
