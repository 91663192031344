import { render, staticRenderFns } from "./index.vue?vue&type=template&id=42c55745&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=42c55745&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexCategoryTag: require('/Users/weizhang/Projects/mingcui_web/components/index/CategoryTag.vue').default,ThreadsListTile: require('/Users/weizhang/Projects/mingcui_web/components/threads/ListTile.vue').default})
